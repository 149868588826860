import { useRouter } from 'vue-router';

function getCategoryFromLine(line) {
    if (line?.productData?.item_category) return line.productData.item_category;
    if (line?.productData?.categories?.length > 0)
        return line.productData.categories[0];
    return null;
}
function trackItem(
    product,
    price,
    quantity,
    page = null,
    event,
    item_list_id = null,
    category = null,
    product_id = null,
) {
    if (category == null) {
        if (page?.value?.breadcrumbs?.length) {
            category =
                page?.value?.breadcrumbs[page?.value?.breadcrumbs.length - 2]
                    .text;
        }
    }
    let totalPrice = parseFloat((price.incl_tax * quantity).toFixed(2));
    let item_id;
    if (product.upc && product.upc.length !== 0) item_id = product.upc;
    else if (product?.children) {
        if (product_id === null) item_id = product?.children[0].upc;
        else item_id = product?.children.find((p) => p.id === product_id)?.upc;
    }

    if (item_id === null || item_id === undefined) {
        item_id = product.id; // Fallback
    }

    const data = {
        event: event,
        ecommerce: {
            currency: 'CHF',
            value: totalPrice,
            items: [
                {
                    item_id: item_id,
                    item_name: product.title,
                    currency: 'CHF',
                    item_brand: 'LUMA Delikatessen',
                    item_category: category,
                    quantity: quantity,
                    price: parseFloat(parseFloat(price.incl_tax).toFixed(2)),
                },
            ],
        },
    };
    if (price.action) {
        // Add Discount if action price
        data.ecommerce.items[0].discount = parseFloat(
            (
                parseFloat(price?.original_incl_tax) -
                parseFloat(price?.incl_tax)
            ).toFixed(2),
        );
    }
    if (item_list_id) {
        data.ecommerce.items[0].item_list_id = item_list_id;
    }
    return data;
}
export function useTrackItemView(product, price, quantity, page) {
    if (process.server) return;
    window.dataLayer.push({ ecommerce: null });
    const data = trackItem(
        product,
        price,
        quantity,
        page,
        'view_item',
        null,
        product.item_category,
    );
    window.dataLayer.push(data);
}

export function useTrackAddToCart(
    product,
    price,
    itemListId,
    quantity,
    category,
    product_id = null,
) {
    if (process.server) return;
    window.dataLayer.push({ ecommerce: null });
    const data = trackItem(
        product,
        price,
        quantity,
        null,
        'add_to_cart',
        itemListId,
        category,
        product_id,
    );
    window.dataLayer.push(data);
}

export function useTrackRemoveFromCart(
    product,
    price,
    itemListId,
    quantity,
    category = null,
) {
    if (process.server) return;
    window.dataLayer.push({ ecommerce: null });
    const data = trackItem(
        product,
        price,
        quantity,
        null,
        'remove_from_cart',
        itemListId,
        category,
    );
    window.dataLayer.push(data);
}

export function useTrackViewCart(items, value) {
    if (process.server) return;
    const productList = [];

    items.forEach((item) => {
        let price = item.productData?.price;
        const category = getCategoryFromLine(item);
        productList.push({
            item_id: item.productData.upc,
            item_name: item.productData.title,
            currency: 'CHF',
            item_brand: 'LUMA Delikatessen',
            item_category: category,
            quantity: item.quantity,
            price: parseFloat(price?.incl_tax),
        });
        if (price?.action) {
            // Add Discount if action price
            productList[productList.length - 1].discount = parseFloat(
                (
                    parseFloat(price?.original_incl_tax) -
                    parseFloat(price.incl_tax)
                ).toFixed(2),
            );
        }
    });
    window.dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            currency: 'CHF',
            value: parseFloat(value) /* total cart value */,
            items: productList,
        },
    });
}

export function useTrackBeginCheckout(items, value) {
    if (process.server) return;
    const productList = [];

    items.forEach((item) => {
        const category = getCategoryFromLine(item);
        let price = item.productData?.price;
        productList.push({
            item_id: item.productData.upc,
            item_name: item.productData.title,
            currency: 'CHF',
            item_brand: 'LUMA Delikatessen',
            item_category: category,
            quantity: item.quantity,
            price: parseFloat(parseFloat(price?.incl_tax).toFixed(2)),
        });
        if (price?.action) {
            // Add Discount if action price
            productList[productList.length - 1].discount = parseFloat(
                (
                    parseFloat(price?.original_incl_tax) -
                    parseFloat(price.incl_tax)
                ).toFixed(2),
            );
        }
    });
    window.dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: 'CHF',
            value: parseFloat(value) /* total cart value */,
            items: productList,
        },
    });
}

export function useTrackPurchase(
    items,
    basket,
    customerAddress,
    customer,
    shippingCost,
    paymentType,
    customerIsNew,
    voucherCode,
    transactionId,
    summary,
) {
    if (process.server) return;
    if (basket.id === window.basket_id) return;
    window.basket_id = basket.id;

    const productList = [];
    items.forEach((item) => {
        const category = getCategoryFromLine(item);
        let price = item.productData.price;
        productList.push({
            item_id: item.productData.upc,
            item_name: item.productData.title,
            currency: 'CHF',
            item_brand: 'LUMA Delikatessen',
            item_category: category,
            quantity: item.quantity,
            price: parseFloat(parseFloat(price?.incl_tax).toFixed(2)),
        });
        if (price.action) {
            // Add Discount if action price
            productList[productList.length - 1].discount = parseFloat(
                (
                    parseFloat(price?.original_incl_tax) -
                    parseFloat(price.incl_tax)
                ).toFixed(2),
            );
        }
    });
    window.dataLayer.push({ ecommerce: null });
    const data = {
        event: 'purchase',
        ecommerce: {
            currency: 'CHF',
            value: parseFloat(
                (
                    parseFloat(basket.total_incl_tax) + // Voucher discount should be already applied
                    parseFloat(shippingCost)
                ).toFixed(2),
            ) /* total cart value */,
            items: productList,
            transaction_id: transactionId,
            tax: parseFloat(basket.total_tax),
            shipping: parseFloat(shippingCost),
            coupon: voucherCode,
            payment_type: paymentType,
        },
        customer: {
            new: customerIsNew,
            email: customer.email,
            phone_number: customer.phone,
            address: {
                first_name: customerAddress.first_name,
                last_name: customerAddress.last_name,
                street: `${customerAddress.street} ${customerAddress.house_number}`,
                city: customerAddress.city,
                postal_code: customerAddress.postcode,
            },
        },
    };
    dataLayer.push(data);
}

export function useTrackCustomerInfo(items, value, checkoutType) {
    if (process.server || !items) return;
    const productList = [];
    items.forEach((item) => {
        let price = item.productData.price;
        const category = getCategoryFromLine(item);
        productList.push({
            item_id: item.productData.upc,
            item_name: item.productData.title,
            currency: 'CHF',
            item_brand: 'LUMA Delikatessen',
            item_category: category,
            quantity: item.quantity,
            price: parseFloat(parseFloat(price?.incl_tax).toFixed(2)),
        });
        if (price.action) {
            // Add Discount if action price
            productList[productList.length - 1].discount = parseFloat(
                (
                    parseFloat(price?.original_incl_tax) -
                    parseFloat(price.incl_tax)
                ).toFixed(2),
            );
        }
    });
    window.dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'add_customer_information',
        ecommerce: {
            currency: 'CHF',
            value: parseFloat(value) /* total cart value */,
            items: productList,
            checkout_type: checkoutType,
        },
    });
}

export function useTrackCustomerAddress(items, value, shippingTier) {
    if (process.server) return;
    const productList = [];
    items.forEach((item) => {
        const category = getCategoryFromLine(item);
        let price = item.productData.price;
        productList.push({
            item_id: item.productData.upc,
            item_name: item.productData.title,
            currency: 'CHF',
            item_brand: 'LUMA Delikatessen',
            item_category: category,
            quantity: item.quantity,
            price: parseFloat(parseFloat(price?.incl_tax).toFixed(2)),
        });
        if (price.action) {
            // Add Discount if action price
            productList[productList.length - 1].discount = parseFloat(
                (
                    parseFloat(price?.original_incl_tax) -
                    parseFloat(price.incl_tax)
                ).toFixed(2),
            );
        }
    });
    window.dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
            currency: 'CHF',
            value: parseFloat(value) /* total cart value */,
            items: productList,
            shipping_tier: shippingTier,
        },
    });
}

export function useTrackSelectItem(
    item,
    itemListId,
    itemListName,
    index,
    price,
) {
    if (process.server) return;
    window.dataLayer.push({ ecommerce: null });
    const data = trackItem(
        item,
        price,
        1,
        null,
        'select_item',
        itemListId,
        item.type,
    );
    data.ecommerce.item_list_name = itemListName;
    data.ecommerce.items[0].index = index;
    dataLayer.push(data);
}
